import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  computed: {
    ...mapGetters([
      "paperGroupId",
      "paperPageDefaultData",
      "paperViewAll",
      "paperPageInfo",
      "paperDragOptions"
    ])
  },
  methods: {
    $DataTypeJudgment(data) {
      return Object.prototype.toString
        .call(data)
        .replace(/^\[object\s/, "")
        .replace(/\]$/, "");
    },
    // 新建page
    async createPaperPage(insert) {
      if (this.paperDragOptions.disabled) return;
      let type = this.$DataTypeJudgment(insert);
      let page = JSON.parse(this.paperPageDefaultData);
      let index = 0;
      switch (type) {
        case "Number":
          index = insert;
          break;
        case "Boolean":
          index = insert === true && this.paperPageInfo.page_index;
          break;
        default:
          index = null;
          break;
      }
      let pageId = await this.$CreateNewPaper({
        parentId: this.paperGroupId
      });
      page._id = page.page_id = pageId;
      this.$PushPaperPage({ data: page, idx: index });
      if (index || index === 0) this.$PaperPageSort(false);

      this.$WsSendMsg({
        action: "NEW_PAGE",
        index: index,
        data: page
      });
      this.$nextTick(() => {
        this.$PaperScrollToIndex(pageId);
      });
    },
    // 新建节点
    creatPaperElement(data) {
      if (data instanceof Array) {
        for (let i = 0; i < data.length; i++) {
          let item = data[i];
          this.$set(
            this.paperViewAll[item.page_index][item.type],
            item.id,
            item
          );
        }
        this.updataPaperInfo(data);
      } else {
        this.$set(this.paperViewAll[data.page_index][data.type], data.id, data);
        this.updataPaperInfo(data);
      }
    },
    // 传输数据格式化
    updataFormat(data) {
      let type = data.type;
      let obj = {};
      let page_id = this.paperViewAll[data.page_index].page_id;
      obj.type = type;
      obj.data = {
        _id: page_id,
        type
      };
      obj.data[type] = [
        {
          id: data.id
        }
      ];
      obj.data[type][0][data.id] = data;
      return obj;
    },
    // 数据更新
    updataPaperInfo(data) {
      let off = data instanceof Array;
      let message = {
        action: off ? "BATCHPUSH" : "PUT"
      };
      if (off) {
        let dataarray = [];
        for (let i = 0; i < data.length; i++) {
          dataarray.push(this.updataFormat(data[i]));
        }
        message.dataarray = dataarray;
      } else {
        Object.assign(message, this.updataFormat(data));
      }

      this.$WsSendMsg(message);
    },
    ...mapMutations(["$PushPaperPage", "$PaperScrollToIndex"]),
    ...mapActions(["$WsSendMsg", "$CreateNewPaper", "$PaperPageSort"])
  }
};
