import { mapGetters, mapMutations, mapActions } from "vuex";
import createPage from "@plugins/createPage";
import { aliyunPut, ossDownload } from "@plugins/aliyunOss";
import {
  parentLocation,
  creatElementId,
  commentDialogOffset,
  formParamsFormat
} from "@plugins/toolWheel";

export default {
  mixins: [createPage],
  computed: {
    ...mapGetters([
      "xppUserInfo",
      "paperGroupId",
      "paperActiveDom",
      "paperViewAll",
      "lastEventCoordinate",
      "paperPageInfo",
      "homeViewScale",
      "paperViewZoomInfo",
      "paperDragOptions"
    ])
  },
  data() {
    return {
      selectedNodeArr: [], // 拖选框选中的节点
      pasteMessagePrev: null
    };
  },
  methods: {
    // 删除节点前数据格式化
    xppDeleteDataFormat(source) {
      if (source !== "revoke") {
        this.$AddXppOperationRecord({
          type: "delete",
          data: this.paperActiveDom
        });
      }
      if (this.paperActiveDom instanceof Array) {
        if (this.paperActiveDom.length === 0) {
          return false;
        }
        let dataarray = [];
        for (let i = 0; i < this.paperActiveDom.length; i++) {
          let item = this.paperActiveDom[i];
          let { id, type, page_id, page_index } = item;
          let obj = {
            type: type,
            data: {
              _id: page_id,
              index: page_index
            }
          };
          obj.data[obj.type] = [
            {
              id
            }
          ];
          dataarray.push(obj);
        }
        return dataarray;
      } else {
        if (!this.paperActiveDom.type) {
          return false;
        }
        let { id, page_id, type, page_index } = this.paperActiveDom;
        let data = {
          _id: page_id,
          type: type,
          index: page_index
        };
        data[data.type] = [
          {
            id
          }
        ];
        return data;
      }
    },
    // 更新节点信息
    upDataPaperNode(data) {
      window.getSelection().removeAllRanges(); // 取消文字选中
      let { id, type, page_index } = this.paperActiveDom;
      let message = {
        action: "PUT",
        type,
        data: {
          _id: this.paperViewAll[page_index].page_id,
          type
        }
      };
      let obj = { id };
      obj[id] = data;
      message.data[type] = [obj];
      this.$SetPaperPageView(message.data);
      this.$WsSendMsg(message);
    },
    // 移除元素
    delDomElement(source = "api") {
      let data = this.xppDeleteDataFormat(source);
      if (data) {
        let params;
        if (data instanceof Array) {
          params = {
            action: "BATCHDELETE",
            dataarray: data
          };
        } else {
          params = {
            action: "DELETE",
            type: this.paperActiveDom.type,
            data
          };
        }
        this.$WsSendMsg(params);
        this.$DelPaperPageView(data);
      }
    },
    // 进入文本编辑模式
    editText(id) {
      this.$EditorFocusEventBind(document.getElementById(id));
    },
    // 接口返回结果后更改节点内容
    creatNodeCallback(page_id, type, data) {
      let message = {
        action: "PUT",
        type,
        data: {
          _id: page_id,
          type
        }
      };
      message.data[type] = [
        {
          id: data.id
        }
      ];
      message.data[type][0][data.id] = data;
      this.$WsSendMsg(message);
    },
    // 新建链接节点
    xppCrearLinkNode({ top, left, link, content }) {
      let { page_id } = this.paperPageInfo;
      let page_index = document.getElementById(page_id).dataset.index;
      let type = "hyperlinkSequence";
      let data = {
        id: creatElementId("link"),
        style: {
          top,
          left,
          width: "650px",
          height: "auto",
          zIndex: "5"
        },
        link,
        content,
        type,
        lock: false,
        page_id,
        page_index,
        canvasthumb: "",
        commentId: "",
        createComment: "",
        commentsTotal: 0,
        commentHistory: false,
        createUserID: this.xppUserInfo.id,
        createDate: new Date() - 0,
        linkLoading: true
      };
      this.$AddXppOperationRecord({
        type: "creat",
        data
      });
      this.$set(this.paperViewAll[page_index][type], data.id, data);
      let linkFinally = res => {
        if (res.code === 0) {
          data.content = res.title;
        } else {
          data.content = "未知链接";
        }
        data.linkLoading = false;
        this.$set(this.paperViewAll[page_index][type], data.id, data);
        this.creatNodeCallback(page_id, type, data);
      };
      this.$axios
        .get("https://s1.jisu.zone/gettitle", {
          params: {
            url: link
          }
        })
        .then(linkFinally)
        .catch(linkFinally);

      this.$nextTick(() => {
        this.$SetPaperActiveDom({
          id: data.id,
          type,
          index: page_index
        });
      });
    },
    // 添加评论
    async commentInfoShow() {
      let { target, pageX, pageY } = this.lastEventCoordinate;
      this.$SetPaperActiveDom(this.forTargetToDate(target));
      let commentId, parentEl, commentEl;
      if (target.classList.contains("xpp-comment-bubble")) {
        commentEl = target;
        commentId = target.dataset.comments;
      }
      [target, parentEl] = parentLocation(target, [
        ".xpp-paper-obj",
        ".xpp-paper-page"
      ]);
      if (!target) {
        console.warn("errcode: target_err");
        return false;
      }
      let targetData = this.paperViewAll[target.dataset.index][
        target.dataset.type
      ][target.id];
      if (!commentId) {
        commentId = targetData.commentId || creatElementId("comment");
      }
      let { data } = await this.$axios.get("/mo/comment/get", {
        params: {
          id: target.id,
          commentId
        }
      });
      let info = {
        commentId,
        targetData,
        offset: commentDialogOffset({
          target,
          parentEl,
          commentEl,
          pageX,
          pageY,
          homeViewScale: this.homeViewScale
        }),
        list: (data && data.comments) || []
      };
      this.$SaveElementCommentInfo(info);
      this.$DiaLoginFoUpDate({
        key: "xppCommentShow",
        value: true,
        exclusive: true
      });
    },
    // 调用aliyun api 下载文件
    aliyunDownFiles(name, response) {
      return ossDownload(name, response);
    },
    // 通知其他paper节点禁用状态
    notiPaperElementDisabled(ids, type) {
      this.$WsSendMsg({
        action: "DISABLEDELEMENT",
        data: {
          ids,
          type
        }
      });
    },
    // 全选当前页节点
    pageNodeSelectAll() {
      let page = document.getElementById(this.paperPageInfo.page_id);
      if (!page) return;
      let child = [...page.children].filter(item =>
        item.classList.contains("xpp-paper-obj")
      );
      for (let i = 0; i < child.length; i++) {
        child[i].classList.add("xpp-select-active");
      }
      this.selectedNodeArr = child;
      this.selectedNodeFun();
    },
    // 节点碰撞检测
    elementCollisionDetection({ x, y, w, h }, pageChildList, back) {
      let arr = [];
      for (let i = 0; i < pageChildList.length; i++) {
        let item = pageChildList[i];
        if (item.dataset.lock || item.classList.contains("xpp-select-nodrop")) {
          continue;
        }
        let { top, left, bottom, right } = item.getBoundingClientRect();
        let offsetY = y >= top ? y <= bottom : y + h >= top,
          offsetX = x >= left ? x <= right : x + w >= left;
        let off = offsetX && offsetY;
        if (off) {
          arr.push(item);
        }
        back && back(item, off);
      }
      return arr;
    },
    // 清空拖选结果
    removeSelectActiveNode() {
      this.selectedNodeArr = [];
      let arr = [...document.getElementsByClassName("xpp-select-active")];
      for (let i = 0; i < arr.length; i++) {
        arr[i].classList.remove("xpp-select-active");
      }
    },
    // 保存拖选框选中节点信息
    selectedNodeFun() {
      if (this.selectedNodeArr.length === 0) {
        return;
      }
      let arr = [];
      let ids = [];
      for (let i = 0; i < this.selectedNodeArr.length; i++) {
        let item = this.selectedNodeArr[i];
        if (!item) continue;
        ids.push(item.id);
        item.classList.remove("ui-selecting");
        item.classList.add("xpp-select-active");
        let data = this.paperViewAll[item.dataset.index][item.dataset.type][
          item.id
        ];
        if (!data) continue;
        data.page_index = item.dataset.index;
        arr.push(data);
      }
      this.$SetPaperActiveDom(arr);
      this.notiPaperElementDisabled(ids, ids.length);
    },
    // 节点锁定与解锁
    xppElementLock(dd) {
      this.$AddXppOperationRecord({
        type: "update",
        data: dd
      });
      if (dd instanceof Array) {
        let target = JSON.parse(sessionStorage.getItem("contextActiveDom"));
        for (let i = 0; i < dd.length; i++) {
          dd[i].lock = !target.lock;
        }
      } else {
        dd.lock = !dd.lock;
      }
      this.$UpdatePaperElementDate(dd);
      this.updataPaperInfo(dd);
    },
    // 复制
    xppElementDataCopy() {
      localStorage.setItem(
        "xppOperateCopy",
        JSON.stringify(
          this.paperActiveDom instanceof Array
            ? this.paperActiveDom
            : [this.paperActiveDom]
        )
      );
    },
    // 粘贴
    async xppElementDataPaste() {
      let data = JSON.parse(localStorage.getItem("xppOperateCopy"));
      if (!data) {
        return;
      }
      this.removeSelectActiveNode();
      let { page_id, page_index } = this.paperPageInfo;
      let time = new Date() - 0;
      for (let i = 0; i < data.length; i++) {
        let item = data[i];
        let { type, style } = item;
        item.copyParentId = item.id;
        if (type === "paperSequence") {
          let { info } = await this.$axios.post(
            "/dis/p/dircopy",
            formParamsFormat({
              originPaperId: item.id,
              title: item.title
            })
          );
          info.originPaperId = info.originId;
          info.newPaperId = info.newId;
          delete info.originId;
          delete info.newId;
          await this.$axios.post(
            "/dis/p/dirpaste",
            formParamsFormat(
              Object.assign(info, {
                parentId: this.paperGroupId
              })
            )
          );
          item.id = info.newPaperId;
          item.title = info.newTitle;
        } else {
          item.id = creatElementId(type.replace("Sequence", ""));
        }
        item.page_id = page_id;
        item.page_index = page_index;
        item.createUserID = this.xppUserInfo.id;
        item.createDate = time;
        item.commentId = "";
        item.createComment = "";
        item.commentsTotal = 0;
        item.updataInfo = {
          userId: this.xppUserInfo.id,
          nickName: this.xppUserInfo.nickName,
          time: time.toString(),
          headImg: this.xppUserInfo.headImg
        };
        style.top =
          style.top.replace("px", "") -
          0 +
          Math.floor(Math.random() * 20 * (Math.random() > 0.5 ? 1 : -1)) /
            this.homeViewScale +
          "px";
        style.left =
          style.left.replace("px", "") -
          0 +
          Math.floor(Math.random() * 20 * (Math.random() > 0.5 ? 1 : -1)) /
            this.homeViewScale +
          "px";
      }
      this.creatPaperElement(data);
      if (this.pasteMessagePrev) {
        this.pasteMessagePrev.close();
      }
      this.pasteMessagePrev = this.$message({
        type: "success",
        message: "粘贴成功",
        onClose: () => {
          this.pasteMessagePrev = null;
        }
      });
      this.$nextTick(() => {
        for (let i in data) {
          this.selectedNodeArr.push(document.getElementById(data[i].id));
        }
        this.selectedNodeFun();
      });
    },
    // 上传缩略图
    canvasthumbUpdata(data) {
      let input = document.getElementById("updataFiles");
      input.multiple = false;
      input.onchange = async ({ target }) => {
        let file = await aliyunPut(target.files[0]);
        if (!file.res) {
          return this.$message.error("上传未完成");
        }
        data.canvasthumb = `https://5gcoolwork.oss-accelerate.aliyuncs.com/${file.name}`;
        data.canvasthumbup = true;
        await this.$axios.post(
          "/mo/paper/updatethumb",
          formParamsFormat({
            paperId: data.id,
            thump: data.canvasthumb,
            thumbup: true,
            rootParentFlag: 0
          })
        );
        let dom = document.getElementById(data.id);
        let con = dom.getElementsByClassName("xpp-hyperlink-con")[0];
        if (con) {
          data.style.height = 120 + 392 + con.clientHeight + "px";
        }
        this.$UpdatePaperElementDate(data);
        this.updataPaperInfo(data);
      };
      input.click();
    },
    // 删除page
    async delPaperPage() {
      if (this.paperDragOptions.disabled) return;

      let { page_id, page_index } = this.paperPageInfo;
      let params = new FormData();
      params.append("paperId", page_id);
      await this.$axios.post("/dis/p/del", params);
      this.$SplicePaperPage(page_index);
      this.$WsSendMsg({
        action: "DEL_PAGE",
        type: "del_page",
        data: { index: page_index }
      });
      let pp = document.getElementById(page_id).parentElement.parentElement
        .previousElementSibling;
      this.$PaperScrollToIndex(
        pp ? pp.querySelector(".xpp-paper-dom-box").id : 0
      );
    },
    // 节点对齐
    xppElementalign(data, type) {
      this.$AddXppOperationRecord({
        type: "update",
        data: data
      });
      let status = data instanceof Array;
      let { width: parentWidth, height: parentHeight } = this.paperViewZoomInfo;
      parentHeight -= 120;
      if (status) {
        let dom = JSON.parse(sessionStorage.getItem("contextActiveDom"));
        let width = dom.style.width.replace("px", "") - 0;
        let height = dom.style.height.replace("px", "") - 0;
        let top = dom.style.top.replace("px", "") - 0;
        let left = dom.style.left.replace("px", "") - 0;
        for (let i = 0; i < data.length; i++) {
          let itemdata = data[i];
          if (itemdata.id === dom.id) {
            continue;
          }
          let itemwidth = itemdata.style.width.replace("px", "") - 0;
          let itemheight = itemdata.style.height.replace("px", "") - 0;
          switch (type) {
            case "left":
              itemdata.style.transition = "left 0.2s";
              itemdata.style.left = left + "px";
              break;
            case "vertical":
              itemdata.style.transition = "left 0.2s";
              itemdata.style.left = left + width / 2 - itemwidth / 2 + "px";
              break;
            case "right":
              itemdata.style.transition = "left 0.2s";
              itemdata.style.left = left + width - itemwidth + "px";
              break;
            case "top":
              itemdata.style.transition = "top 0.2s";
              itemdata.style.top = top + "px";
              break;
            case "horizontal":
              itemdata.style.transition = "top 0.2s";
              itemdata.style.top = top + height / 2 - itemheight / 2 + "px";
              break;
            case "bottom":
              itemdata.style.transition = "top 0.2s";
              itemdata.style.top = top + height - itemheight + "px";
              break;
          }
        }
      } else {
        let width = data.style.width.replace("px", "") - 0;
        let height = data.style.height.replace("px", "") - 0;
        switch (type) {
          case "left":
            data.style.transition = "left 0.2s";
            data.style.left = "0";
            break;
          case "vertical":
            data.style.transition = "left 0.2s";
            data.style.left = parentWidth / 2 - width / 2 + "px";
            break;
          case "right":
            data.style.transition = "left 0.2s";
            data.style.left = parentWidth - width + "px";
            break;
          case "top":
            data.style.transition = "top 0.2s";
            data.style.top = "0";
            break;
          case "horizontal":
            data.style.transition = "top 0.2s";
            data.style.top = parentHeight / 2 - height / 2 + "px";
            break;
          case "bottom":
            data.style.transition = "top 0.2s";
            data.style.top = parentHeight - height + "px";
            break;
        }
      }
      this.$UpdatePaperElementDate(data);
      this.updataPaperInfo(data);

      setTimeout(() => {
        if (status) {
          for (let i = 0; i < data.length; i++) {
            data[i].style.transition = null;
          }
        } else {
          data.style.transition = null;
        }
      }, 300);
    },
    // 调整显示层级
    elementViewIndexAdjust(target, type, data) {
      this.$AddXppOperationRecord({
        type: "update",
        data
      });
      let zIndex = target.style.zIndex - 0;
      if (zIndex <= 0 && type === "to_bottom") return;
      let {
          offsetLeft: x,
          offsetTop: y,
          offsetWidth: w,
          offsetHeight: h
        } = target,
        arr = [],
        zArr = [];

      let pageChildList = target.parentElement.children;
      for (let i = 0; i < pageChildList.length; i++) {
        let item = pageChildList[i];
        if (item.dataset.lock || item.classList.contains("xpp-select-nodrop")) {
          continue;
        }
        let {
            offsetLeft: left,
            offsetTop: top,
            offsetWidth,
            offsetHeight
          } = item,
          bottom = offsetHeight + top,
          right = left + offsetWidth;
        let offsetY = y >= top ? y <= bottom : y + h >= top,
          offsetX = x >= left ? x <= right : x + w >= left;
        let off = offsetX && offsetY;
        if (off && item.id !== target.id) {
          zArr.push({ item: i, zIndex: item.style.zIndex - 0 });
          arr.push(item);
        }
      }
      zArr = zArr.sort((a, b) => {
        return a.zIndex - b.zIndex;
      });
      for (let i = 0; i < zArr.length; i++) {
        let item = zArr[i];
        if (type === "to_top") {
          if (item.zIndex >= zIndex) {
            zIndex = item.zIndex + 1;
            break;
          }
        } else if (type === "to_bottom") {
          if (item.zIndex <= zIndex) {
            zIndex = item.zIndex - 1;
            break;
          }
        }
      }
      data.style.zIndex = zIndex;
      this.$UpdatePaperElementDate(data);
      this.upDataPaperNode(data);
    },
    // 根据节点信息返回state中对应的数据信息
    forTargetToDate(target) {
      try {
        let { index, type } = target.dataset;
        if (index && type) {
          return this.paperViewAll[index][type][target.id];
        } else {
          return false;
        }
      } catch (err) {
        console.warn(err);
        return false;
      }
    },
    ...mapMutations([
      "$DelPaperPageView",
      "$SetPaperPageView",
      "$PaperScrollToIndex",
      "$DiaLoginFoUpDate",
      "$SaveElementCommentInfo",
      "$SetPaperActiveDom",
      "$SplicePaperPage"
    ]),
    ...mapActions(["$WsSendMsg", "$CreateNewPaper", "$EditorFocusEventBind"])
  }
};
